import React, { Component } from 'react';
import './LeftNavigationBar.css';
import { Home, BarChart2, Layers, MessageCircle, Archive, Search, HelpCircle, DollarSign } from 'react-feather';
import NavigationItem from '../NavigationItem/NavigationItem';
import ContactModal from '../../../container/ContactModal/ContactModal';
import { NavLink } from 'react-router-dom';

import { Modal } from 'reactstrap';

import maxmoneyCoin from '../../../assets/images/maxmoney/maxmoney-coin.png';
import TrackableClick from '../../TrackableClick';


class LeftNavigationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContactModal: false
        }
    }

    toggleModalHandler = () => {
        this.setState(prevState => ({
            showContactModal: !prevState.showContactModal
        }));
    }

    render() {
        return (
            <div>
                <nav className="sidebar mb-sidebar">
                    <div className="LeftNavigationBar">
                        <div className="text-center pt-3 pb-3" style={{ backgroundColor: "RGBA(0,0,0,0.2)" }}>
                            {/* <a className="nav-link" data-toggle="modal" onClick={this.toggleModalHandler}>
                                <Circle size={16} />
                            </a> */}

                            <button className="btn btn-sm btn-link" onClick={this.toggleModalHandler} type="button">
                                <img className='headshot' src={`${process.env.REACT_APP_RESOURCE_URI}/images/headshots/${this.props.affiliate.affiliate_manager.image}`} alt={`${this.props.affiliate.affiliate_manager.name}`} />
                            </button>
                            <div className="link-title" onClick={this.toggleModalHandler}>
                                <button className="btn btn-sm btn-link" style={{ color: "grey", textDecoration: "none" }}>
                                    <div className="btn btn-sm btn-outline-light">{this.props.affiliate.affiliate_manager.name} | <MessageCircle size={14} style={{ marginTop: '-3px' }} /></div>
                                </button>
                                <Modal isOpen={this.state.showContactModal} toggle={this.toggleModalHandler} className="modal-sm">
                                    <ContactModal affiliate={this.props.affiliate} />
                                </Modal>
                            </div>
                        </div>

                        <ul className="nav mt-3">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Dashboard" >
                                    <NavLink to="/dashboard" className="nav-link" activeClassName="active-nav">
                                        <Home size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/dashboard" styleName="nav-link">Dashboard</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Reports" >
                                    <NavLink to="/reports" className="nav-link" activeClassName="active-nav">
                                        <BarChart2 size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/reports" styleName="nav-link">Reports</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Browse" >
                                    <NavLink to="/browse" className="nav-link" activeClassName="active-nav">
                                        <Layers size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/browse" styleName="nav-link">Browse</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Search" >
                                    <NavLink to="/search" className="nav-link" activeClassName="active-nav">
                                        <Search size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/search" styleName="nav-link">Search</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Referral Program" >
                                    <NavLink to="/refer" className="nav-link" activeClassName="active-nav">
                                        <DollarSign size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/refer" styleName="nav-link">Referral Program</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="MaxMoney" >
                                    <NavLink to="/maxmoney" className="nav-link" activeClassName="active-nav">
                                        <img src={maxmoneyCoin} style={{ maxWidth: '15px', width: '100%', marginLeft: '1px' }} alt="MaxMoney Coin" />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/maxmoney" styleName="nav-link">MaxMoney</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Resources" >
                                    <NavLink to="/resources" className="nav-link" activeClassName="active-nav">
                                        <Archive size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/resources" styleName="nav-link">Resources</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="Blog" >
                                    <a href="https://blogmaxbounty.com/" className="nav-link">
                                        <MessageCircle size={16} />
                                    </a>
                                    <span className="link-title" style={{ marginLeft: "10px" }}>
                                        <a target="_blank" href="https://blogmaxbounty.com/" rel="noopener noreferrer">Blog</a>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                        <ul className="nav">
                            <li>
                                <TrackableClick element_type="Navigation Link" reference_name="FAQ" >
                                    <NavLink to="/faq" className="nav-link" activeClassName="active-nav">
                                        <HelpCircle size={16} />
                                    </NavLink>
                                    <span className="link-title">
                                        <NavigationItem link="/faq" styleName="nav-link">FAQ</NavigationItem>
                                    </span>
                                </TrackableClick>
                            </li>
                        </ul>

                    </div>
                </nav>
            </div>
        );
    }
}

export default LeftNavigationBar;
