import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Menu } from 'react-feather';
import { Link } from 'react-router-dom';

import './NavigationDropup.css';
import TrackableClick from '../../../TrackableClick';

class NavigationDropup extends Component {

    state = {
        dropdownOpen: false
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    className="dropdown-toggle"
                >
                    <Menu className="feather-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="font-weight-bold text-dark">Navigation</DropdownItem>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Dashboard" >
                        <Link className="dropdown-news-item dropdown-item" to="/" onClick={this.toggle}>Dashboard</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Reports" >
                        <Link className="dropdown-news-item dropdown-item" to="/reports/earnings" onClick={this.toggle}>Reports</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Browse" >
                        <Link className="dropdown-news-item dropdown-item" to="/browse/new" onClick={this.toggle}>Browse</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Search" >
                        <Link className="dropdown-news-item dropdown-item" to="/search" onClick={this.toggle}>Search</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Refferal Program" >
                        <Link className="dropdown-news-item dropdown-item" to="/refer" onClick={this.toggle}>Referral Program</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="MaxMoney" >
                        <Link className="dropdown-news-item dropdown-item" to="/maxmoney" onClick={this.toggle}>MaxMoney</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Resources" >
                        <Link className="dropdown-news-item dropdown-item" to="/resources" onClick={this.toggle}>Resources</Link>
                    </TrackableClick>
                    <DropdownItem divider className="m-0" />
                    <TrackableClick element_type="Navigation Link" reference_name="Blog" >
                        <DropdownItem tag="a" href="https://blog.maxbounty.com/" target="_blank" className="dropdown-news-item">Blog</DropdownItem>
                    </TrackableClick>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default NavigationDropup;